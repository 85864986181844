import styled from 'styled-components';
import tokens from 'barn/tokens';
import { fontSizes } from 'barn/tokens/typography';
import { LoadingScreen } from 'src/components/LoadingScreen';

export const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #343744;
  color: ${tokens.colors.greys[8]};
  width: 100vw;
  height: 100vh;
`;

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes[4]};
`;

const Login = () => {
  return <LoadingScreen />;
};

export default Login;
